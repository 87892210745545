import React from "react"
import Logo from "../components/Logo"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <Logo />
    </Layout>
  )
}
